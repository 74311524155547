.TagRowElement {
  text-align: center;
  /* display: flex; */
}
.TagElement {
  border-radius: 3px;
  font-size: 90%;
  font-family: monospace;
  font-weight: 600;
  padding: 0.25rem 0.3rem;
  margin: 0.3rem 0.4rem;
  text-wrap: nowrap;
  float:left;
}