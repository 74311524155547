.NavBar {
    clear: both;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    /* height: 1rem; */
    background-color: #111;
    z-index: 20;
    box-shadow: #111 0 0 5px;
}

@media print {
    .NavBar {
        display: none;
    }
}

.NavBar .title {
    font-size: 160%;
    margin: 0.3rem 1rem;
    font-weight: 600;
    float: left;
    color: var(--primary)
}

.NavBar-link {
    float: right;

    a {
        max-height: 2rem;
        margin: 0.25rem 0.25rem;
        transform: scale(100);
    }

    svg {
        height: 1.75rem;
        margin: 0.25rem 0.25rem;
    }

    img {
        max-height: 26px;
        margin: 0.25rem 0.25rem;
    }
}

.NavBar .version {
    float: right;
    background-color: #363636;
}

.NavBar .clear-float {
    clear: both;
}

/* span.version {
    float: right;
} */