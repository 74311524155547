.WorkSectionItemElement {

  /* flex: 0 1 ; */
  >.heading {
    /* border-left: 2px solid #47bbe9; */
    border-left: 2px solid var(--accent);
    margin-right: 0.5rem;

    >.title {
      >.heading-dates {
        font-size: 80%;
        color: var(--accent);
        /* float: right; */
      }
    }

    >span.type {
      color: var(--primary);
      font-size: 80%;
      background-color: var(--bg-cell);
      border-radius: 3px;
      float: right;
      margin: 0.1rem;
      padding: 0.1rem 0.2rem;
    }

    h4 {
      margin: 0.5rem 0 0.5rem 0.5rem;
    }
  }

  >.content {
    /* padding: 0 0.5rem; */
    margin-bottom: 1rem;

    p {
      margin: 0.25rem 0 0.5rem 0;
    }
  }
}

@media screen and (min-width: 900px) {
  .WorkSectionItemElement {
    flex: 2 1 100%;
  }
}

@media print {
  .WorkSectionItemElement {
    break-inside: avoid;
    break-after: auto;
    flex: 0 1 100%;
  }
}