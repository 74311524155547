:root {
  --primary: #ff9900;
  /* --accent: #ffd597; */
  --accent: #78d4ff;
  --text: #ffffff;
  --bg-cell: #333;
  --bg-color: #111;
  --bg-header: #051016;
  --bg-light: #444;
  --text-muted: #aaa;
  --breakpoint-sm: 600px;
  --breakpoint-md: 900px;
  --breakpoint-lg: 1200px;

  --max-width-outter-content: 80%;
  --max-width-inner-content: var(--breakpoint-xl);

  @media print {
    --accent: #ff9900;
    --primary: #002e78;
    --text: #000;
    --bg-cell: #ccc;
    --bg-color: #f3f3f3;
    --bg-header: #eee;
    --bg-light: #ccc;
    --text-muted: #666;
    font-size: 90%;
  }
}

.row {
  display: flex;
  float: clear;
}

.cols-1 {
  flex: 1 1;
}

.cols-2 {
  flex: 1 1;
}

.cols-3 {
  flex: 1 1;
}

.cols-4 {
  flex: 1 1;
}

.cols-6 {
  flex: 1 1;
}

.col-1 {
  flex: 1 1;
}

.col-2 {
  flex: 1 1;
}

.col-3 {
  flex: 1 1;
}

.col-4 {
  flex: 1 1;
}

.col-6 {
  flex: 1 1;
}

.col-sm-1 {
  @media screen and (min-width: 540px) {
    flex: 1 1;
  }
}

.col-sm-2 {
  @media screen and (min-width: 540px) {
    flex: 1 1;
  }
}

.col-sm-3 {
  @media screen and (min-width: 540px) {
    flex: 1 1;
  }
}

.col-sm-4 {
  @media screen and (min-width: 540px) {
    flex: 1 1;
  }
}

.col-sm-6 {
  @media screen and (min-width: 540px) {
    flex: 1 1;
  }
}

.col-md-1 {
  @media screen and (min-width: 900px) {
    flex: 1 1;
  }
}

.col-md-2 {
  @media screen and (min-width: 900px) {
    flex: 1 1;
  }
}

.col-md-3 {
  @media screen and (min-width: 900px) {
    flex: 1 1;
  }
}

.col-md-4 {
  @media screen and (min-width: 900px) {
    flex: 1 1;
  }
}

.col-md-6 {
  @media screen and (min-width: 900px) {
    flex: 1 1;
  }
}

.col-lg-1 {
  @media screen and (min-width: 1200px) {
    flex: 1 1;
  }
}

.col-lg-2 {
  @media screen and (min-width: 1200px) {
    flex: 1 1;
  }
}

.col-lg-3 {
  @media screen and (min-width: 1200px) {
    flex: 1 1;
  }
}

.col-lg-4 {
  @media screen and (min-width: 1200px) {
    flex: 1 1;
  }
}

.col-lg-6 {
  @media screen and (min-width: 1200px) {
    flex: 1 1;
  }
}

.display-block {
  display: block;
}

.display-block-sm {
  @media screen and (min-width: 540px) {
    display: block;
  }
}

.display-block-md {
  @media screen and (min-width: 900px) {
    display: block;
  }
}

.display-block-lg {
  @media screen and (min-width: 1200px) {
    display: block;
  }
}

.display-inline {
  display: inline;
}

.display-inline-sm {
  @media screen and (min-width: 540px) {
    display: inline;
  }
}

.display-inline-md {
  @media screen and (min-width: 900px) {
    display: inline;
  }
}

.display-inline-lg {
  @media screen and (min-width: 1200px) {
    display: inline;
  }
}

.display-none {
  display: none;
}

@media print {
  .print-display-none {
    display: none;
  }
}

/* .print-display-none {
  @media print {
    display: none;
  }
} */

.text-primary {
  color: var(--primary)
}

.text-accent {
  color: var(--accent)
}

.text-muted {
  color: var(--text-muted) !important
}

@font-face {
  font-family: 'Anton';
  src: local('Anton'), url(../public/assets/Anton-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #f6f6f6;
}

a,
a:visited {
  /* color: #3a96d7; */
  color: var(--primary);

  text-decoration: none;
}

a:hover {
  filter: brightness(80%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tag-sm-light {
  color: #fff;
}

.clear {
  clear: both;
}