.App {
  text-align: center;
}

.ResumeSectionElement {
  margin-top: 5rem;
}

.ResumeSectionElement>h2 {
  color: var(--text-muted);
  font-family: 'Anton';
  font-weight: 400;
  text-align: left;
  margin: 1rem;
}