.ResumePage {
  >.header {
    background-color: var(--bg-header);
    min-height: 80vh;
    /* padding: 1rem 3rem 0 3rem; */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    color: white;
    padding-top: 6%;

    .TagElement {
      font-size: 60%;
      background-color: var(--bg-color);
      padding: 0.5rem 1rem;
    }

    >.intro {
      max-width: calc(900px * 0.9);
      margin: 0 10%;

      /* .heading {
        font-family: 'Anton';
        font-size: 100px;
        letter-spacing: -5px;
        vertical-align: top;
      } */
      >.image {
        /* float:left; */
        /* background-color: black; */
        /* border-radius: 5%; */
        display: none;

        margin: 1rem;

        img {
          max-height: 12vh;
          pointer-events: none;
        }
      }

      /* >.message {
        font-size: 16px;
        max-width:800px;
        margin: 0 auto;
      } */
      >.content {
        text-align: left;

        h1 {
          color: var(--accent);
          font-family: 'Anton';
          font-size: 120px;
          letter-spacing: -5px;
          height: 9rem;
          padding: 0;
          vertical-align: baseline;
          margin: 0 auto;
        }

        h2 {
          color: var(--primary);
          font-family: 'Anton';
          margin: 0 auto;
          padding: 0;
        }

        p {
          font-size: 70%;
        }
      }
    }

    >.content {
      font-size: 70%;
      margin-top: 3rem;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      break-inside: avoid;
    }
  }

  >.footer {
    img {}
  }
}

@media print {
  .ResumePage {
    >.header {
      >.intro {
        max-width: 80%;

        >.image {
          img {
            max-height: 16vh;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .ResumePage {
    >.header {
      >.intro {
        >.image {
          display: block;
          margin-top: 2rem;
          float: left;
        }
      }
    }
  }
}