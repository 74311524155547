.ResumeSectionElement {
  margin-top: 5rem;
  max-width: var(--breakpoint-lg);
  margin: 0 auto;
  >h2 {
    color: var(--text-muted);
    font-family: 'Anton';
    font-weight: 400;
    text-align: left;
    margin: 1rem;
  }
}

@media screen and (min-width: 900px) {
  .ResumeSectionElement {
    margin: 3rem auto;
  }
}

@media print {
  .ResumeSectionElement {
    break-before: auto;
    /* break-inside: avoid-column; */
  }
}