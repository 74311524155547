.IconRowElement {
  /* height: 3rem; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 auto;
  margin: 3rem 0 0 0;
  justify-content: space-evenly;
  width: 100%;
  img {
    filter: saturate(100%);
    height: 2rem;
    border-radius: 3px;
    margin: 0.75rem;
    /* padding: 0.75rem; */
    /* margin: 0.25rem auto; */
    text-align: center;
    display: flex;
  }
}
@media print {
  .IconRowElement {
    visibility: hidden;
    display: none;
    height: 0;
  }
}