h3 small {
  margin-left: 0.5rem;
}

.WorkSectionElement {
  margin: 1rem auto;
  padding: 1rem 0;
  /* text-align: left; */
  position: relative;
  background-color: var(--bg-color);

  >.heading {
    break-inside: avoid;
    break-before: avoid;
    text-align: center;
    margin-bottom: 2rem;

    h2 {
      margin: 0.5rem 0 0.1rem 0;

      /* text-decoration: underline; */
    }

    h3 {
      margin: -0.1rem 0 0.5rem 0;
      color: var(--text-muted)
    }

    .title-secondary {
      font-size: 110%;
    }

    .title-detail {
      font-size: 80%;
    }

    >.icon {
      display: block;

      img {
        /* right: 0; */
        /* float: right; */
        max-height: 5vh;
        border-radius: 0.1rem;
        margin: 0 1rem;
      }
    }
  }

  >.content {
    text-align: left;
    margin-top: 2rem;

    >.items {
      margin: 0 auto;
      padding: 0.5rem;
    }
  }

  >.footer {
    >.tags {
      margin-top: 2rem;
    }
  }
}

@media screen and (min-width: 600px) {

  /* @media screen and (min-width: 900px) { */
  .WorkSectionElement {
    margin: 1rem 10%;
    text-align: left;

    >.heading {
      text-align: left;
      margin: 0 2rem;

      >.icon {
        /* margin: 0 auto; */
        right: 0;
        /* display: block; */
        text-align: center;
        /* position: relative; */
        /* padding: 0rem; */
        /* clear: both; */

        img {
          /* display: block; */
          right: 0;
          /* float: none; */
          position: absolute;
          left: unset;
          clear: both;
        }
      }
    }

    >.content {
      padding: 0 2rem;

      >.items {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        flex-basis: content;

        >:first-child {
          break-inside: avoid;
          break-before: avoid;
        }
      }
    }

    /* >.footer {
      padding: 0 8rem;
    } */
  }
}

@media print {
  .tag {
    background-color: var(--bg-cell);
  }

  .WorkSectionElement {
    border: 1px solid var(--bg-cell) !important;
    background-color: var(--bg-light);

    >:first {
      /* break-before: avoid;
      break-inside: avoid;
      break-inside: avoid; */
    }

    >.heading {
      /* break-before: avoid;
      break-inside: avoid;
      break-after: avoid; */

      >.icon {
        /* display: block; */
        /* text-align: left; */
        /* width: 100%; */

        img {
          /* margin: 0rem auto; */
          padding-top: 1rem auto;
          /* float: none; */
          /* position: relative; */
          /* left: unset; */
          /* display: block; */
          filter: saturate(0) opacity(100) invert();
        }
      }
    }

    >.content {
      break-before: avoid;
      padding: 0 2rem;

      >.items {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        flex-basis: content;
        /* gap: 0.5rem; */
        margin: 0 auto;
        padding: 0;

        >:first-child {
          break-inside: avoid;
          break-before: avoid;
        }
      }
    }

    >.footer {
      margin-top: 2rem;

      .tag {
        color: var(--text-muted);
      }
    }
  }
}